import React, { useState } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';

const ItemPrice = props => {
  const { t } = useTranslation();

  const moneyMask = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 10,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...moneyMask,
  });

  const [price, setPrice] = useState(props.price);

  return (
    <form>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">{t('labels.price')}</label>
        <div className="col-sm-10">
          <MaskedInput
            mask={currencyMask}
            className="form-control"
            placeholder={t('labels.price')}
            value={price}
            onInput={e => setPrice(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label"></label>
        <div className="col-sm-10">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => props.handleSaveItemPriceClick(price)}
          >
            {t('labels.save')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ItemPrice;
