import * as actionTypes from '../actions/actionTypes';
import { updatedObject } from '../utils';

const initialState = {
  error: null,
  loading: false,
  tabInfo: {},
  orderItems: [],
  groups: [],
  products: [],
  selectedGroup: null,
  successMessage: null,
  savedOrderInfo: null,
  showConfirmPrintModal: false,
  autoPrint: false,
};

const fetchProductsGroupsInit = state => {
  return updatedObject(state, { loading: true });
};

const fetchProductsGroupsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    groups: action.groups,
    products: action.groups[0].products,
    selectedGroup: action.groups[0].id,
  });
};

const fetchProductsGroupsFail = (state, action) => {
  return updatedObject(state, { loading: false, error: action.error });
};

const cleanOrderList = state => {
  return updatedObject(state, {
    loading: false,
    error: null,
    orderItems: [],
  });
};

export const selectGroup = (state, action) => {
  const filteredGroupIndex = state.groups.findIndex(
    group => group.id === action.groupId
  );
  return updatedObject(state, {
    products: state.groups[filteredGroupIndex].products,
    selectedGroup: action.groupId,
  });
};

export const addOrderItem = (state, action) => {
  let changedOrderItems = [...state.orderItems];
  const prodOrderIndex = changedOrderItems.findIndex(
    item => item.id === action.itemId
  );
  if (prodOrderIndex >= 0) {
    changedOrderItems[prodOrderIndex].orderQuantity++;
  } else {
    const prodIndex = state.products.findIndex(
      item => item.id === action.itemId
    );
    if (prodIndex > -1) {
      const prodInfo = { ...state.products[prodIndex] };
      prodInfo.orderQuantity = 1;
      changedOrderItems.push(prodInfo);
    }
  }
  return updatedObject(state, {
    orderItems: changedOrderItems,
  });
};

const removeOrderItem = (state, action) => {
  let changedOrderItems = [...state.orderItems];
  const prodOrderIndex = state.orderItems.findIndex(
    item => item.id === action.itemId
  );
  if (prodOrderIndex >= 0) {
    changedOrderItems[prodOrderIndex].orderQuantity--;
  }
  if (changedOrderItems[prodOrderIndex].orderQuantity === 0) {
    changedOrderItems.splice(prodOrderIndex, 1);
  }

  return updatedObject(state, {
    orderItems: changedOrderItems,
  });
};

const selectTab = (state, action) => {
  return updatedObject(state, { tabInfo: action.tabInfo });
};

const unselectTab = state => {
  return updatedObject(state, { tabInfo: {} });
};

const setOrderItemPrice = (state, action) => {
  let changedOrderItems = [...state.orderItems];
  const prodOrderIndex = state.orderItems.findIndex(
    item => item.id === action.itemId
  );
  if (prodOrderIndex >= 0) {
    changedOrderItems[prodOrderIndex].price = action.price;
  }

  return updatedObject(state, {
    orderItems: changedOrderItems,
  });
};

const setOrderItemNote = (state, action) => {
  let changedOrderItems = [...state.orderItems];
  const prodOrderIndex = state.orderItems.findIndex(
    item => item.id === action.itemId
  );
  if (prodOrderIndex >= 0) {
    changedOrderItems[prodOrderIndex].note = action.note;
  }

  return updatedObject(state, {
    orderItems: changedOrderItems,
  });
};

const orderCheckoutInit = state => {
  return updatedObject(state, { loading: true });
};

const orderCheckoutFail = (state, action) => {
  return updatedObject(state, { loading: false, error: action.error });
};

const orderCheckoutSuccess = (state, action) => {
  let autoPrint = false;
  let showConfirmPrintModal = false;
  // 1 - PDF manual,  2 - PDF auto, 3 - Printnode Manual, 4 - Printnode Auto
  if (
    action.createdOrderInfo.printType === '1' ||
    action.createdOrderInfo.printType === '3'
  ) {
    showConfirmPrintModal = true;
    //showConfirmPrintToggle();
  } else if (
    action.createdOrderInfo.printType === '2' ||
    action.createdOrderInfo.printType === '4'
  ) {
    autoPrint = true;
    //handlePrint(response.data.tabId, response.data.orderId);
  }

  return updatedObject(state, {
    loading: false,
    successMessage: action.createdOrderInfo.message,
    savedOrderInfo: action.createdOrderInfo,
    showConfirmPrintModal: showConfirmPrintModal,
    autoPrint: autoPrint,
  });
};

const toggleShowConfirmPrintModal = state => {
  return updatedObject(state, {
    showConfirmPrintModal: !state.showConfirmPrintModal,
  });
};

const cleanConfirmedOrderInfo = state => {
  return updatedObject(state, {
    loading: false,
    successMessage: null,
    savedOrderInfo: null,
    showConfirmPrintModal: false,
    autoPrint: false,
  });
};

const cleanError = state => {
  return updatedObject(state, { error: null });
};

const cleanSuccessMessage = state => {
  return updatedObject(state, { successMessage: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS_GROUPS_INIT:
      return fetchProductsGroupsInit(state);

    case actionTypes.FETCH_PRODUCTS_GROUPS_SUCCESS:
      return fetchProductsGroupsSuccess(state, action);

    case actionTypes.FETCH_PRODUCTS_GROUPS_FAIL:
      return fetchProductsGroupsFail(state, action);

    case actionTypes.SELECT_GROUP:
      return selectGroup(state, action);

    case actionTypes.ADD_ORDER_ITEM:
      return addOrderItem(state, action);

    case actionTypes.SET_ORDER_ITEM_PRICE:
      return setOrderItemPrice(state, action);

    case actionTypes.SET_ORDER_ITEM_NOTE:
      return setOrderItemNote(state, action);

    case actionTypes.REMOVE_ORDER_ITEM:
      return removeOrderItem(state, action);

    case actionTypes.CLEAN_ORDER_LIST:
      return cleanOrderList(state);

    case actionTypes.SELECT_TAB:
      return selectTab(state, action);

    case actionTypes.UNSELECT_TAB:
      return unselectTab(state);

    case actionTypes.ORDER_CHECKOUT_INIT:
      return orderCheckoutInit(state);

    case actionTypes.ORDER_CHECKOUT_FAIL:
      return orderCheckoutFail(state, action);

    case actionTypes.ORDER_CHECKOUT_SUCCESS:
      return orderCheckoutSuccess(state, action);

    case actionTypes.TOGGLE_SHOW_CONFIRM_PRINT_MODAL:
      return toggleShowConfirmPrintModal(state);

    case actionTypes.CLEAN_CONFIRMED_ORDER_INFO:
      return cleanConfirmedOrderInfo(state);

    case actionTypes.CLEAN_ERROR:
      return cleanError(state);

    case actionTypes.CLEAN_SUCCESS_MESSAGE:
      return cleanSuccessMessage(state);

    default:
      return state;
  }
};

export default reducer;
