import React from 'react';

const Loader = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <div className="jumbotron bg-transparent min-vh-100 text-center m-0 d-flex justify-content-center">
            <span className="spinner-border spinner-border-lg mr-3"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
