export const FETCH_PROPERTY_INFO_INIT = 'FETCH_PROPERTY_INFO_INIT';
export const FETCH_PROPERTY_INFO_SUCCESS = 'FETCH_PROPERTY_INFO_SUCCESS';
export const FETCH_PROPERTY_INFO_FAIL = 'FETCH_PROPERTY_INFO_FAIL';

export const FETCH_TABS_INIT = 'FETCH_TABS_INIT';
export const FETCH_TABS_SUCCESS = 'FETCH_TABS_SUCCESS';
export const FETCH_TABS_FAIL = 'FETCH_TABS_FAIL';
export const FILTER_TABS = 'FILTER_TABS';
export const EMPTY_TABS = 'EMPTY_TABS';

export const FETCH_PRODUCTS_GROUPS_INIT = 'FETCH_PRODUCTS_GROUPS_INIT';
export const FETCH_PRODUCTS_GROUPS_SUCCESS = 'FETCH_PRODUCTS_GROUPS_SUCCESS';
export const FETCH_PRODUCTS_GROUPS_FAIL = 'FETCH_PRODUCTS_GROUPS_FAIL';
export const SELECT_GROUP = 'SELECT_GROUP';

export const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM';
export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM';
export const CLEAN_ORDER_LIST = 'CLEAN_ORDER_LIST';
export const SET_ORDER_ITEM_PRICE = 'SET_ORDER_ITEM_PRICE';
export const SET_ORDER_ITEM_NOTE = 'SET_ORDER_ITEM_NOTE';

export const ORDER_CHECKOUT_INIT = 'ORDER_CHECKOUT_INIT';
export const ORDER_CHECKOUT_FAIL = 'ORDER_CHECKOUT_FAIL';
export const ORDER_CHECKOUT_SUCCESS = 'ORDER_CHECKOUT_SUCCESS';
export const ORDER_CHECKOUT = 'ORDER_CHECKOUT';
export const TOGGLE_SHOW_CONFIRM_PRINT_MODAL =
  'TOGGLE_SHOW_CONFIRM_PRINT_MODAL';
export const CLEAN_CONFIRMED_ORDER_INFO = 'CLEAN_CONFIRMED_ORDER_INFO';

export const CLEAN_ERROR = 'CLEAN_ERROR';
export const CLEAN_SUCCESS_MESSAGE = 'CLEAN_SUCCESS_MESSAGE';

export const SELECT_TAB = 'SELECT_TAB';
export const UNSELECT_TAB = 'UNSELECT_TAB';

export const FETCH_GUESTS_SUCCESS = 'FETCH_GUESTS_SUCCESS';
export const FETCH_GUESTS_FAIL = 'FETCH_GUESTS_FAIL';
export const FETCH_GUESTS_INIT = 'FETCH_GUESTS_INIT';
