export const TOKEN_KEY = '@hqbeds-Token';
export const PROPERTY = '@hqbeds-PROPERTY';
export const LANG = '@hqbeds-LANG';

export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_KEY) !== null;
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const setLanguage = language => {
  localStorage.setItem(LANG, language);
};

export const getLanguage = () => {
  return localStorage.getItem(LANG);
};

export const setProperty = property => {
  localStorage.setItem(PROPERTY, property);
};

export const unsetProperty = () => {
  localStorage.removeItem(PROPERTY);
};

export const getProperty = () => {
  return localStorage.getItem(PROPERTY);
};
