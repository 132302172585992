import styled from 'styled-components';

export const ProductCard = styled.div``;

export const ProductCardButton = styled.div`
  color: #000;
  transition: 0.2s all ease-out;
  &:hover {
    top: -4px;
  }
`;
