import React from 'react';
import { Container, Main } from './styles.js';
import Nav from '../Nav';

const layout = props => {
  const containerClass = props.fluid ? 'container-fluid' : 'container';
  return (
    <Container>
      <Nav />
      <Main className={`${containerClass} mt-4`}>{props.children}</Main>
      <footer className={`${containerClass} footer mt-5`}>
        <hr />
        <p className="text-muted">&copy; hqbeds</p>
      </footer>
    </Container>
  );
};

export default layout;
