import React, { useState, useEffect } from 'react';

import GroupSelector from './GroupSelector';
import Product from './Product';

const ProductPicker = props => {
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    let newFilteredProductList = props.products?.filter(
      prod => filter === '' || new RegExp(filter, 'i').test(prod.name)
    );

    setFilteredProductList(newFilteredProductList);
  }, [filter, props.products]);

  const handleProductFiltering = event => {
    const newFilter = event.target.value;

    let newFilteredProductList = props.products?.filter(
      prod => newFilter === '' || new RegExp(newFilter, 'i').test(prod.name)
    );

    setFilter(newFilter);

    setFilteredProductList(newFilteredProductList);
  };

  return (
    <>
      <GroupSelector
          groups={props.groups}
          clickedGroupHandler={props.clickedGroupHandler}
          selectedGroup={props.selectedGroup}
        />
 
      <div className="mt-3 mb-3">
        <form className="my-auto d-inline w-100">
          <input
            type="search"
            className="form-control"
            id="product"
            placeholder="Product..."
            onChange={handleProductFiltering}
          />
        </form>
      </div>

      <div className="mb-3">
        <div className="row">
          {filteredProductList &&
            filteredProductList.map(product => {
              return (
                <Product
                  key={product.id}
                  product={product}
                  clickedHandler={props.clickedProductHandler}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ProductPicker;
