import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Layout from '../../components/Layout/Layout';
import TextInput from '../../components/Form/TextInput';
import SearchGuest from '../../components/Guests/Search';

import { fetchGuests } from '../../store/actions';

import * as tabsRepository from '../../repositories/tabs';

import Modal from '../../components/Modal';
import useForm from '../../hooks/useForm';

const TabRegister = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [guest, setGuest] = useState(null);

  const initialFormControlState = {
    number: {
      type: 'number',
      label: t('labels.tab'),
      value: '',
      elementConfig: {
        placeholder: t('labels.number'),
      },
      validation: {
        required: true,
        numeric: true,
      },
      valid: false,
      validationError: t('errors.invalidNumber'),
      touched: false,
    },
    name: {
      type: 'text',
      label: t('labels.name'),
      value: '',
      elementConfig: {
        placeholder: t('labels.name'),
      },
      validation: {
        required: true,
        minLength: 2,
      },
      valid: false,
      validationError: t('errors.invalidValue'),
      touched: false,
    },
    email: {
      type: 'text',
      label: 'E-mail',
      value: '',
      elementConfig: {
        placeholder: 'E-mail',
      },
      validation: {
        required: false,
        email: true,
      },
      valid: true,
      validationError: t('errors.invalidEmail'),
      touched: false,
    },
    phone: {
      type: 'text',
      label: t('labels.phone'),
      value: '',
      elementConfig: {
        placeholder: t('labels.phone'),
      },
      validation: {},
      valid: true,
      validationError: '',
      touched: false,
    },
  };

  const {
    isValid,
    changeHandler,
    formControls,
    setFormControls,
    resetForm,
    formElementsArray,
  } = useForm(initialFormControlState);

  let formElements = formElementsArray.map(element => {
    return (
      <TextInput
        key={element.id}
        type={element.config.type}
        label={element.config.label}
        elementConfig={element.config.elementConfig}
        shouldBeValidated={element.config.validation}
        invalid={!element.config.valid}
        touched={element.config.touched}
        validationError={element.config.validationError}
        value={element.config.value}
        changed={event => changeHandler(event, element.id)}
      />
    );
  });

  const [showSearchGuestModal, setShowSearchGuestModal] = useState();
  const modalLoading = useSelector(state => state.guestsReducer.loading);
  const guests = useSelector(state => state.guestsReducer.guests);
  const error = useSelector(state => state.guestsReducer.error);

  useEffect(() => {
    toast.error(error);
  }, [error]);

  const registerHandler = event => {
    event.preventDefault();

    const formData = {};
    for (let formElementIdenfitifer in formControls) {
      formData[formElementIdenfitifer] =
        formControls[formElementIdenfitifer].value;
    }

    if( formData['number'] === '' ){

      toast.error('Digite o número da comanda');

    } else {

      formData['guest'] = guest !== null ? guest.id : null;
      formData['booking'] = guest !== null ? guest.booking_id : null;
  
      setLoading(true);

      tabsRepository
        .register(formData)
        .then(response => {
          toast.success(response.data.message);
          cleanFullPageForm();
        })
        .catch(error => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
      }
  };

  const dispatch = useDispatch();

  const handleGuestClick = guest => {
    setGuest(guest);

    const modifiedForm = {
      ...formControls,
    };

    const modifiedFormName = { ...modifiedForm['name'] };
    modifiedFormName.value = guest.name + ' ' + guest.surname;
    modifiedFormName.valid = true;
    modifiedFormName.touched = true;
    modifiedForm['name'] = modifiedFormName;

    if (guest.email && guest.email !== '') {
      const modifiedFormEmail = { ...modifiedForm['email'] };
      modifiedFormEmail.value = guest.email;
      modifiedFormEmail.valid = true;
      modifiedFormEmail.touched = true;
      modifiedForm['email'] = modifiedFormEmail;
    }

    if (guest.phone && guest.phone !== '') {
      const modifiedFormPhone = { ...modifiedForm['phone'] };
      modifiedFormPhone.value = guest.phone;
      modifiedFormPhone.valid = true;
      modifiedFormPhone.touched = true;
      modifiedForm['phone'] = modifiedFormPhone;
    }

    setFormControls(modifiedForm);

    setShowSearchGuestModal(!showSearchGuestModal);
  };

  const searchGuestModalToggle = () => {
    dispatch(fetchGuests());
    setShowSearchGuestModal(!showSearchGuestModal);
  };

  const cleanFullPageForm = () => {
    resetForm();
    setGuest(null);
  };

  let fullForm = (
    <form className="form" autoComplete="on" onSubmit={registerHandler}>

      {formElements}

      <div className="form-group row">
        <label className="col-lg-2 col-form-label form-control-label">
          {t('labels.guest')}
        </label>
        <div className="col-lg-10">
          {!guest ? (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={searchGuestModalToggle}
            >
              <AiOutlineSearch className="mr-2" />
              {t('labels.linkTabToGuest')}
            </button>
          ) : (
            <div className="pt-2">
              <span>{guest.name}</span>
              <FaEdit
                size={18}
                onClick={() => searchGuestModalToggle()}
                className="ml-2 mb-1"
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="form-group row mt-5">
        <label className="col-lg-2 col-form-label form-control-label"></label>
        <div className="col-lg-10">
          <button
            type="reset"
            className="btn btn-light"
            onClick={cleanFullPageForm}
          >
            {t('labels.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            disabled={!isValid && !guest}
          >
            {t('labels.save')}
          </button>
        </div>
      </div>
    </form>
  );

  if (loading) {
    fullForm = <span className="spinner-border spinner-border-lg mr-3"></span>;
  }

  return (
    <>
      <Modal
        show={showSearchGuestModal}
        toggleModal={searchGuestModalToggle}
        loading={modalLoading}
        title={t('labels.guests')}
      >
        <SearchGuest guests={guests} handleGuestClick={handleGuestClick} />
      </Modal>

      <Layout>
        <div className="card card-outline-secondary">
          <div className="card-header">
            <h1 className="mb-0">{t('labels.register')}</h1>
          </div>
          <div className="card-body">{fullForm}</div>
        </div>
      </Layout>
    </>
  );
};

export default TabRegister;
