import React from 'react';

const TextInput = props => {
  const inputClasses = ['form-control'];

  let errorTag = '';
  if (props.touched && props.invalid && props.shouldBeValidated) {
    inputClasses.push('is-invalid');

    if (props.validationError) {
      errorTag = (
        <div className="invalid-feedback">{props.validationError}</div>
      );
    }
  }

  return (
    <div className="form-group row">
      <label className="col-lg-2 col-form-label form-control-label">
        {props.label}
      </label>
      <div className="col-lg-10">
        <input
          type={props.type}
          className={inputClasses.join(' ')}
          value={props.value}
          onChange={props.changed}
          {...props.elementConfig}
        />
        {errorTag}
      </div>
    </div>
  );
};

export default TextInput;
