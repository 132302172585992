import * as actionTypes from '../actions/actionTypes';
import { updatedObject } from '../utils';

const initialState = {
  error: null,
  loading: false,
  guests: [],
};

const fetchGuestsInit = state => {
  return updatedObject(state, { loading: true });
};

const fetchGuestsSuccess = (state, action) => {
  return updatedObject(state, {
    error: null,
    loading: false,
    guests: action.guests,
  });
};

const fetchGuestsFail = (state, action) => {
  return updatedObject(state, { loading: false, error: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GUESTS_INIT:
      return fetchGuestsInit(state);
    case actionTypes.FETCH_GUESTS_FAIL:
      return fetchGuestsFail(state);
    case actionTypes.FETCH_GUESTS_SUCCESS:
      return fetchGuestsSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
