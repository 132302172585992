import React from "react";

import { ProductCard, ProductCardButton } from "./styles.js";

const Product = (props) => {
  return (
    <ProductCard
      className="col-xs-12 col-sm-6 col-md-6 col-lg-4 py-2"
      key={props.product.id}
    >
      <ProductCardButton
        onClick={() => props.clickedHandler(props.product.id)}
        className="btn btn-link card card-body text-decoration-none h-100 shadow-sm p-3 g-white rounded"
      >
        <h6 className="card-title text-decoration-none text-decoration-none">
          {props.product.name}
        </h6>
      </ProductCardButton>
    </ProductCard>
  );
};

export default Product;
