import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { useTranslation } from 'react-i18next';

import api from '../../services/api';
import {
  login,
  isAuthenticated,
  setLanguage,
  getProperty,
} from '../../services/auth';
import { Container, Form } from './styles.js';

const CheckUrlParams = () => {
  const location = useLocation();
  const { search: qs } = location;
  const urlParams = queryString.parse(qs);
  if (urlParams.language) {
    setLanguage(urlParams.language);
  }
  if (urlParams.token) {
    login(urlParams.token);
  }
};

const SignIn = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const property = getProperty();

  CheckUrlParams();

  const onChangeEmail = e => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = e => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleSignIn = async e => {
    e.preventDefault();

    setLoading(true);

    if (!email || !password) {
      toast.error(t('errors.fillTheForm'));
      setLoading(false);
    } else {
      try {
        const response = await api.post('/auth/signin', {
          property,
          email,
          password,
        });
        login(response.data.token);
        setLanguage(response.data.language);
        i18next.changeLanguage(response.data.language);
      } catch (err) {
        toast.error(t('errors.sigInError'));
        setLoading(false);
      }
    }
  };

  if (isAuthenticated()) {
    return <Redirect to={`/${property}/order`} />;
  }

  return (
    <Container className="container">
      <div className="login-column m-auto">
        <div className="col-12 login-box">
          <Form onSubmit={handleSignIn}>
            <h2 className="text-center mb-4">{t('labels.signin')}</h2>

            <div className="form-group">
              <label htmlFor="property" className="font-weight-bold">
                {t('labels.property')}:
              </label>
              <br />
              <h2>{property}</h2>
            </div>
            <div className="form-group">
              <label htmlFor="username" className="font-weight-bold">
                E-mail:
              </label>
              <br />
              <input
                type="email"
                name="email"
                id="email"
                className="form-control rounded-0"
                onChange={onChangeEmail}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password" className="font-weight-bold">
                {t('labels.password')}:
              </label>
              <br />
              <input
                type="password"
                name="password"
                id="password"
                className="form-control rounded-0"
                onChange={onChangePassword}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                name="signinButton"
                className="btn btn-primary login-btn btn-block rounded-0"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm mr-3"></span>
                )}
                {t('labels.signin')}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(SignIn);
