import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LANG } from '../services/auth';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'pt-br',
    fallbackLng: ['pt-br', 'english', 'es'],
    debug: false,
  });

i18n.use(LanguageDetector).init({
  detection: {
    order: ['localStorage'],
    lookupLocalStorage: LANG,
    caches: ['localStorage'],
  },
});

export default i18n;
