import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './style.css';
import Loader from '../Loader';

const Modal = props => {
  const { t } = useTranslation();

  const modalSizeClass = props.small
    ? 'customModalContainer customModalContainerSmall'
    : props.medium
    ? 'customModalContainer customModalContainerMedium'
    : 'customModalContainer';

  let modalBody = '';
  if (props.show) {
    modalBody = (
      <div>
        <div className="customModalOverlay"></div>
        <div className={modalSizeClass}>
          <div className="header">
            <button
              type="button"
              title="Close"
              className="close"
              onClick={e => props.toggleModal(e)}
            >
              <span>&times;</span>
            </button>
            <h3>{props.title}</h3>
          </div>
          <div className="body">
            {props.loading ? <Loader /> : props.children}
          </div>
          <footer>
            <button
              type="button"
              className="btn btn-danger"
              onClick={e => props.toggleModal(e)}
            >
              {t('labels.close')}
            </button>

            {props.handleConfirmationButtonClick && (
              <button
                type="button"
                className="btn btn-success"
                onClick={props.handleConfirmationButtonClick}
              >
                {t('labels.confirm')}
              </button>
            )}
          </footer>
        </div>
      </div>
    );
  }

  return modalBody;
};

Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

export default Modal;
