import * as actionTypes from '../actions/actionTypes';
import { updatedObject } from '../utils';

const initialState = {
  error: null,
  loading: false,
  tabs: [],
};

const fetchTabsInit = state => {
  return updatedObject(state, { loading: true });
};

const fetchTabsSuccess = (state, action) => {
  return updatedObject(state, {
    error: null,
    tabs: action.tabs,
    loading: false,
  });
};

const fetchTabsFail = (state, action) => {
  return updatedObject(state, { error: action.error, loading: false });
};

const emptyTabs = state => {
  return updatedObject(state, {
    loading: false,
    tabs: [],
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TABS_INIT:
      return fetchTabsInit(state);
    case actionTypes.FETCH_TABS_SUCCESS:
      return fetchTabsSuccess(state, action);
    case actionTypes.FETCH_TABS_FAIL:
      return fetchTabsFail(state, action);
    case actionTypes.EMPTY_TABS:
      return emptyTabs(state);
    default:
      return state;
  }
};

export default reducer;
