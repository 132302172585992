import React, { useState } from 'react';
import api from '../../services/api';

const prettyPrintJson = ({ data }) => {
  return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

const Property = () => {
  const [propInfo, setPropInfo] = useState();

  async function handleGetPropertyInfoClick() {
    const info = await api.get('/property/info');
    setPropInfo(info);
  }
  return (
    <div style={{ padding: '40px' }}>
      <button onClick={handleGetPropertyInfoClick}>Get Property Info</button>
      <div>{propInfo && prettyPrintJson(propInfo)}</div>
    </div>
  );
};

export default Property;
