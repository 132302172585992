import * as actionTypes from './actionTypes';
import * as productsRepository from '../../repositories/products';
import * as tabsRepository from '../../repositories/tabs';

export const cleanOrderList = () => {
  return {
    type: actionTypes.CLEAN_ORDER_LIST,
  };
};

export const selectGroup = groupId => {
  return {
    type: actionTypes.SELECT_GROUP,
    groupId: groupId,
  };
};

export const fetchProductsGroupsInit = () => {
  return {
    type: actionTypes.FETCH_PRODUCTS_GROUPS_INIT,
  };
};

export const fetchProductsGroupsFail = error => {
  return {
    type: actionTypes.FETCH_PRODUCTS_GROUPS_FAIL,
    error: error,
  };
};

export const fetchProductsGroupsSuccess = groups => {
  return {
    type: actionTypes.FETCH_PRODUCTS_GROUPS_SUCCESS,
    groups: groups,
  };
};

export const addOrderItem = itemId => {
  return {
    type: actionTypes.ADD_ORDER_ITEM,
    itemId: itemId,
  };
};

export const removeOrderItem = itemId => {
  return {
    type: actionTypes.REMOVE_ORDER_ITEM,
    itemId: itemId,
  };
};

export const setOrderItemPrice = (itemId, price) => {
  return {
    type: actionTypes.SET_ORDER_ITEM_PRICE,
    itemId: itemId,
    price: price,
  };
};

export const setOrderItemNote = (itemId, note) => {
  return {
    type: actionTypes.SET_ORDER_ITEM_NOTE,
    itemId: itemId,
    note: note,
  };
};

export const selectTab = tabInfo => {
  return {
    type: actionTypes.SELECT_TAB,
    tabInfo: tabInfo,
  };
};

export const unselectTab = () => {
  return {
    type: actionTypes.UNSELECT_TAB,
  };
};

export const fetchProductsGroups = (onlyGroups = false) => {
  return dispatch => {
    dispatch(fetchProductsGroupsInit());
    productsRepository.fetchProductsGroups(onlyGroups)
      .then(response => {
        dispatch(fetchProductsGroupsSuccess(response.data.result));
      })
      .catch(error => {
        dispatch(
          fetchProductsGroupsFail(
            'There was a problem within get groups request.'
          )
        );
      });
  };
};

export const orderCheckoutInit = () => {
  return {
    type: actionTypes.ORDER_CHECKOUT_INIT,
  };
};

export const orderCheckoutFail = error => {
  return {
    type: actionTypes.ORDER_CHECKOUT_FAIL,
    error: error,
  };
};

export const orderCheckoutSuccess = createdOrderInfo => {
  return {
    type: actionTypes.ORDER_CHECKOUT_SUCCESS,
    createdOrderInfo: createdOrderInfo,
  };
};

export const orderCheckout = (tabInfo, orderItems) => {
  return dispatch => {
    dispatch(orderCheckoutInit());
    tabsRepository.checkout(tabInfo, orderItems)
      .then(response => {
        dispatch(cleanOrderList());
        dispatch(unselectTab());
        dispatch(orderCheckoutSuccess(response.data));
      })
      .catch(error => {
        dispatch(orderCheckoutFail(error.message));
      });
  };
};

export const toggleShowConfirmPrintModal = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.TOGGLE_SHOW_CONFIRM_PRINT_MODAL,
    });
  };
};

export const cleanConfirmedOrderInfo = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAN_CONFIRMED_ORDER_INFO,
    });
  };
};

export const cleanError = error => {
  return {
    type: actionTypes.CLEAN_ERROR,
    error: null,
  };
};

export const cleanSuccessMessage = error => {
  return {
    type: actionTypes.CLEAN_SUCCESS_MESSAGE,
    successMessage: null,
  };
};
