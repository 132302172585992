import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Loader from './components/Loader';
import App from './App';
import './helper/i18n';

import propertyReducer from './store/reducers/property';
import tabsReducer from './store/reducers/tabs';
import orderReducer from './store/reducers/order';
import guestsReducer from './store/reducers/guests';

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;


const tabsTerminalStore = createStore(
  combineReducers({
    propertyReducer: propertyReducer,
    tabsReducer: tabsReducer,
    orderReducer: orderReducer,
    guestsReducer: guestsReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Provider store={tabsTerminalStore}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
