import api from '../../services/api';

const fetchPropertyInfo = () => {
  return api.get('/property/info').then(response => {
    if (response.data.status) {
      return response;
    }
    throw new Error(response.data.message);
  })
}

export { fetchPropertyInfo };
