import React from 'react';

import { NavPill, NavPillLink } from './styles.js';

const GroupSelector = props => {
  return (
    <ul className="nav nav-pills">
      {Array.isArray(props.groups) && props.groups.length > 0 && props.groups.map(group => {
        let active = props.selectedGroup === group.id ? 'active' : '';
        return (
          <NavPill className="nav-item" key={group.id}>
            <NavPillLink
              className="btn btn-link nav-link text-secondary"
              active={active}
              onClick={() => props.clickedGroupHandler(group.id)}
            >
              {group.name}
            </NavPillLink>
          </NavPill>
        );
      })}
    </ul>
  );
};

export default GroupSelector;
