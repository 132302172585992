import api from '../../services/api';

const fetchTabs = () => {
  return api.get('/tabs/openedTabs').then(response => {
    if (response.data.status) {
      return response;
    }
    throw new Error(response.data.message);
  })
}

const register = formData => {
  return api.post('/tabs/saveHead', formData).then(response => {
    if (response.data.success) {
      return response;
    }
    throw new Error(response.data.message);
  });
};

const checkout = (tabInfo, orderItems) => {
  return api
    .post('/tabs/checkout', {
      tabId: tabInfo.id,
      tabNumber: tabInfo.tab_number,
      tabItems: orderItems,
    }).then(response => {
      if (response.data.success) {
        return response;
      }
      throw new Error(response.data.message);
    });
};


export { fetchTabs, register, checkout };
