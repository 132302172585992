import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { AiOutlineSearch } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';

import Layout from '../../components/Layout/Layout';
import ProductPicker from '../../components/Order/ProductPicker';
import Modal from '../../components/Modal';
import Search from '../../components/Tabs/Search';
import OrderItems from '../../components/Order/OrderItems';
import ItemPrice from '../../components/Order/ItemPrice';
import Loader from '../../components/Loader';
import ItemNote from '../../components/Order/ItemNote';
import { openCouponWindow } from '../../helper/print';

import api from '../../services/api';

import {
  fetchProductsGroups,
  selectGroup,
  addOrderItem,
  removeOrderItem,
  setOrderItemPrice,
  setOrderItemNote,
  cleanOrderList,
  selectTab,
  unselectTab,
  orderCheckout,
  cleanConfirmedOrderInfo,
  cleanError,
  cleanSuccessMessage,
  fetchPropertyInfo,
} from '../../store/actions';

const Order = () => {
  const { t } = useTranslation();

  const loading = useSelector(state => state.orderReducer.loading);
  const groups = useSelector(state => state.orderReducer.groups);
  const selectedGroup = useSelector(state => state.orderReducer.selectedGroup);
  const products = useSelector(state => state.orderReducer.products);
  const error = useSelector(state => state.orderReducer.error);

  const propertyInfo = useSelector(state => state.propertyReducer);

  const orderItems = useSelector(state => state.orderReducer.orderItems);
  const tabInfo = useSelector(state => state.orderReducer.tabInfo);

  const [modalLoading, setModalLoading] = useState(false);
  const [showSearchTabModal, setShowSearchTabModal] = useState(false);
  const [tabs, setTabs] = useState([]);

  const savedOrderInfo = useSelector(
    state => state.orderReducer.savedOrderInfo
  );
  const showConfirmPrintModal = useSelector(
    state => state.orderReducer.showConfirmPrintModal
  );
  const successMessage = useSelector(
    state => state.orderReducer.successMessage
  );
  const autoPrint = useSelector(state => state.orderReducer.autoPrint);

  const [showAdjustItemPriceModal, setShowAdjustItemPriceModal] = useState(
    false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPropertyInfo(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductsGroups(false));
  }, [dispatch]);

  const handleProductGroupClick = productGroupId => {
    dispatch(selectGroup(productGroupId));
  };

  const handleProductClick = productId => {
    dispatch(addOrderItem(productId));
  };

  const handleProductRemoveClick = productId => {
    dispatch(removeOrderItem(productId));
  };

  const emptyCart = () => {
    dispatch(cleanOrderList());
    dispatch(unselectTab());
  };

  const memoizedTotal = useMemo(
    () =>
      orderItems.reduce(
        (subtotal, item) => subtotal + item.orderQuantity * item.price,
        0
      ),
    [orderItems]
  );

  const [updateItemPrice, setUpdateItemPrice] = useState(0.0);
  const [updateItemNote, setUpdateItemNote] = useState('');
  const [updateItemId, setUpdateItemId] = useState();

  const toggleAdjustItemPriceModal = e => {
    setShowAdjustItemPriceModal(!showAdjustItemPriceModal);
  };

  const handleChangeItemPrice = itemInfo => {
    setUpdateItemPrice(itemInfo.price > 0 ? itemInfo.price : null);
    setUpdateItemId(itemInfo.id);
    toggleAdjustItemPriceModal();
  };

  const confirmDefinePriceClick = newPrice => {
    dispatch(setOrderItemPrice(updateItemId, newPrice));
    toggleAdjustItemPriceModal();
  };

  const searchTabModalToggle = e => {
    setShowSearchTabModal(!showSearchTabModal);
  };

  const searchTab = () => {
    setModalLoading(true);
    searchTabModalToggle();
    api
      .get('/tabs/openedTabs')
      .then(response => {
        if (response.data.status === true) {
          setTabs(response.data.result);
        }
      })
      .catch(error => {
        console.log('Error', error);
      })
      .finally(() => {
        setModalLoading(false);
      });
  };

  const handleTabSelect = tab => {
    dispatch(selectTab(tab));
    searchTabModalToggle();
  };

  const orderConfirmHandler = () => {
    dispatch(orderCheckout(tabInfo, orderItems));
  };

  useEffect(() => {
    if (autoPrint && savedOrderInfo) {
      handlePrint(savedOrderInfo.tabId, savedOrderInfo.orderId);
      dispatch(cleanConfirmedOrderInfo());
    }
  }, [autoPrint, savedOrderInfo, dispatch]);

  const handlePrint = (tabId, orderId) => {
    api
      .post('/tabs/print', { tabId: tabId, orderId: orderId })
      .then(response => {
        if (response.data.status && response.data.result.printType) {
          openCouponWindow(response.data.result.pdfUrl);
        }
      })
      .catch(error => {
        console.log('Print Error', error);
      });
  };

  const handleConfirmPrintClick = () => {
    handlePrint(savedOrderInfo.tabId, savedOrderInfo.orderId);
    dispatch(cleanConfirmedOrderInfo());
  };

  const handleCancelPrintClick = () => {
    dispatch(cleanConfirmedOrderInfo());
  };

  useEffect(() => {
    toast.error(error);
    return () => {
      dispatch(cleanError());
    };
  }, [error, dispatch]);

  useEffect(() => {
    toast.success(successMessage);
    return () => {
      dispatch(cleanSuccessMessage());
    };
  }, [successMessage, dispatch]);

  const handleChangeItemNote = itemInfo => {
    setUpdateItemNote(itemInfo.note > 0 ? itemInfo.note : '');
    setUpdateItemId(itemInfo.id);
    toggleShowAddItemNoteModal();
  };
  const [showAddItemNoteModal, setShowAddItemNoteModal] = useState(false);
  const toggleShowAddItemNoteModal = () => {
    setShowAddItemNoteModal(!showAddItemNoteModal);
  };

  const handleSaveItemNote = note => {
    dispatch(setOrderItemNote(updateItemId, note));
    toggleShowAddItemNoteModal();
  };

  return (
    <Layout>
      <Modal
        show={showAdjustItemPriceModal}
        toggleModal={toggleAdjustItemPriceModal}
        title={t('labels.defineItemPrice')}
        small
      >
        <ItemPrice
          price={updateItemPrice}
          handleSaveItemPriceClick={confirmDefinePriceClick}
        />
      </Modal>

      <Modal
        show={showAddItemNoteModal}
        toggleModal={toggleShowAddItemNoteModal}
        title={t('labels.notes')}
        medium
      >
        <ItemNote
          note={updateItemNote}
          handleSaveItemNoteClick={handleSaveItemNote}
        />
      </Modal>

      <Modal
        show={showSearchTabModal}
        toggleModal={searchTabModalToggle}
        title={t('labels.tabs')}
        loading={modalLoading}
      >
        <Search tabs={tabs} handleTabSelect={handleTabSelect} />
      </Modal>

      <Modal
        show={showConfirmPrintModal}
        toggleModal={handleCancelPrintClick}
        title={t('labels.tabPrintConfirmation')}
        handleConfirmationButtonClick={handleConfirmPrintClick}
        small
      ></Modal>

      <h1>{t('labels.sale')}</h1>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-md-5">
                <ProductPicker
                  clickedProductHandler={handleProductClick}
                  clickedGroupHandler={handleProductGroupClick}
                  groups={groups}
                  products={products}
                  selectedGroup={selectedGroup}
                />
            </div>

            <div className="col-md-7">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>
                        {t('labels.resume')}
                        {tabInfo?.id && (
                          <p className="mt-2 mb-0 pb-0">
                            {t('labels.tab')}
                            <span className="ml-1 mr-2">
                              {tabInfo.tab_number}
                            </span>
                            <small>({tabInfo.name})</small>
                            <FaEdit
                              size={18}
                              onClick={() => searchTab()}
                              className="ml-1 mb-1"
                              style={{ cursor: 'pointer' }}
                            />
                          </p>
                        )}
                      </h5>
                    </div>
                    <div className="text-right">
                      <small>
                        {propertyInfo?.orderTime !== '' && propertyInfo?.orderTime}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <OrderItems
                    orderItems={orderItems}
                    handleProductRemoveClick={handleProductRemoveClick}
                    handleProductClick={handleProductClick}
                    handleChangeItemPrice={handleChangeItemPrice}
                    handleChangeItemNote={handleChangeItemNote}
                    handleSaveItemNote={handleSaveItemNote}
                    total={memoizedTotal}
                  />
                </div>
                <div className="card-footer">
                  {orderItems?.length > 0 && (
                    <button
                      className="btn btn-danger float-left"
                      title={t('labels.cancel')}
                      onClick={() => emptyCart()}
                    >
                      {t('labels.cancel')}
                    </button>
                  )}
                  {tabInfo?.id && orderItems?.length > 0 && (
                    <button
                      className="btn btn-success float-right"
                      onClick={orderConfirmHandler}
                      title={t('labels.finish')}
                    >
                      {t('labels.finish')}
                    </button>
                  )}

                  {!tabInfo?.id && (
                    <button
                      className="btn btn-secondary float-right btn-sm ml-1"
                      title={t('labels.search')}
                      onClick={() => searchTab()}
                    >
                      <AiOutlineSearch size={15} className="mr-1" />
                      {t('labels.search')} {t('labels.tab')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Order;
