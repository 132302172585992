import React from 'react';
import { useTranslation } from 'react-i18next';

const MainDetail = props => {
  const { t } = useTranslation();

  return (
    <dl className="row mt-2">
      <dt className="col-sm-2">{t('labels.tab')}:</dt>
      <dd className="col-sm-9">{props.tab?.tab_number}&nbsp;</dd>
      <dt className="col-sm-2">{t('labels.name')}:</dt>
      <dd className="col-sm-9">{props.tab?.name}&nbsp;</dd>
      <dt className="col-sm-2">E-mail:</dt>
      <dd className="col-sm-9">{props.tab?.email}&nbsp;</dd>
      <dt className="col-sm-2">{t('labels.phone')}:</dt>
      <dd className="col-sm-9">{props.tab?.phone}&nbsp;</dd>
      {props.tab?.tbk_id && (
        <>
          <dt className="col-sm-2">{t('labels.booking')}:</dt>
          <dd className="col-sm-9">{props.tab?.tbk_id}&nbsp;</dd>
        </>
      )}
    </dl>
  );
};

export default MainDetail;
