import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { fetchProductsGroups } from '../../store/actions';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader';
import api from '../../services/api';

import { CustomCard } from './styles.js';

const OrdersMonitor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState({});
  const groups = useSelector(state => state.orderReducer.groups);

  useEffect(() => {
    if (!Array.isArray(groups) || groups.length === 0) {
      dispatch(fetchProductsGroups(true));
    }
  }, [dispatch, groups]);

  useEffect(() => {
    if (Array.isArray(groups) && groups.length) {
      const initialCategoryList = {};
      for (let k in groups) {
        initialCategoryList[groups[k].id] = true;
      }
      setCategories(initialCategoryList);
    }
  }, [groups]);

  useEffect(() => {
    const getOrders = async categories => {
      try {
        let selectedCategories = '';
        for (const c in categories) {
          if (categories[c] === true) {
            if (selectedCategories !== '') {
              selectedCategories += ',';
            }
            selectedCategories += c;
          }
        }
        const response = await api.get('/tabs/orders', {
          params: { categories: selectedCategories },
        });
        if (response.data.status === true) {
          setOrders(response.data.result);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    getOrders(categories);

    const getOrderCallId = setInterval(() => {
      getOrders(categories);
    }, 15000);

    return () => clearInterval(getOrderCallId);
  }, [categories]);

  const handleCardDoubleClick = (tabId, orderId) => {
    try {
      api
        .post('/tabs/toggleOrderStatus', {
          tabId,
          orderId,
        })
        .then(response => {
          if (response.status) {
            const clonedOrders = [...orders];
            orders.forEach((order, orderIndex) => {
              if (order.pos_tab_id === tabId && order.order_id === orderId) {
                clonedOrders[orderIndex].orderStatus =
                  clonedOrders[orderIndex].orderStatus === '1' ? '0' : '1';
              }
            });
            setOrders(clonedOrders);
          }
        });
    } catch (err) {
      toast.error('Error');
    }
  };

  const handleCategorySelect = event => {
    const categoriesCopy = { ...categories };
    categoriesCopy[event.target.value] = event.target.checked;
    setCategories(categoriesCopy);
  };

  return (
    <Layout fluid={true}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row col-12">
            <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 col-xl-1">
              <label className="h5">{t('labels.categories')}:</label>
            </div>
            <div className="col-xs-12 col-sm-9 col-md-10 col-lg-10 col-xl-11">
              {groups.map(group => {
                return (
                  <div
                    key={group.id}
                    className="form-check form-check-inline ml-2"
                  >
                    <input
                      className="form-check-input"
                      style={{ cursor: 'pointer' }}
                      type="checkbox"
                      id={`category_${group.id}`}
                      value={group.id}
                      name={`category_${group.id}`}
                      defaultChecked={true}
                      onChange={handleCategorySelect}
                    />
                    <label
                      className="form-check-label"
                      style={{ cursor: 'pointer' }}
                      htmlFor={`category_${group.id}`}
                    >
                      {group.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row">
            {orders.map(order => {
              return (
                <div
                  className="col-xs-12 col-sm-6 col-md-4 col-lg-3 py-2"
                  key={order.pos_tab_id + order.order_id}
                >
                  <CustomCard
                    className="card card-body h-100 text-white"
                    status={order.orderStatus}
                    style={{ cursor: 'pointer' }}
                    onDoubleClick={() =>
                      handleCardDoubleClick(order.pos_tab_id, order.order_id)
                    }
                  >
                    <h6 className="mb-0">
                      {t('labels.tab')}: {order.tab_number} -{' '}
                      {t('labels.order')}: {order.order_id}
                      <br />
                      <small>{order.item_date}</small>
                    </h6>
                    <p>
                      <small>{order.name}</small>
                    </p>
                    <div className="mt-2">
                      <ul className="row mb-0 list-unstyled">
                        {order.items &&
                          order.items.map(item => {
                            return (
                              <li key={item.id} className="col-sm-9 mb-2 h-100">
                                {item.name}:
                                <span className="badge badge-secondary ml-2">
                                  {item.quantity}
                                </span>
                                {item.note !== '' && (
                                  <>
                                    <br />
                                    <small>{item.note}</small>
                                  </>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </CustomCard>
                </div>
              );
            })}
          </div>
        </>
      )}
    </Layout>
  );
};

export default OrdersMonitor;
