import React from 'react';
import { useTranslation } from 'react-i18next';
import MainDetail from '../MainDetail';

const Cancellation = props => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="h5 mt-3 mb-5">{t('labels.confirmTabCancellation')}</div>
      <MainDetail tab={props.tab} />
    </div>
  );
};

export default Cancellation;
