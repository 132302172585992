import React, { Component } from "react";
import ErrorFallback from "./components/ErrorFallback";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // Log error info somewhere
  }

  render() {
    if (this.state.errorInfo) {
      return <ErrorFallback error={this.state.errorInfo} />
    }
    return this.props.children;
  }
}
