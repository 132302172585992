import React from 'react';
import { useTranslation } from 'react-i18next';

const Search = props => {
  const { t } = useTranslation();

  return (
    <div className="table-responsive">
      <table className="table table-striped table-sm table-hover mt-1">
        <thead>
          <tr>
            <th>{t('labels.booking')}</th>
            <th>{t('labels.name')}</th>
            <th>{t('labels.email')}</th>
          </tr>
        </thead>
        <tbody>
          {props.guests?.map(guest => {
            return (
              <tr
                key={guest.id}
                onClick={() => props.handleGuestClick(guest)}
                style={{ cursor: 'pointer' }}
              >
                <th>{guest.tbk_id}</th>
                <td>
                  {guest.name} {guest.surname}
                </td>
                <td>{guest.email}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Search;
