import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { MdPlaylistAdd } from 'react-icons/md';

const OrderItems = props => {
  const { t } = useTranslation();
  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-collapsed">
          <thead>
            <tr>
              <th>{t('labels.item')}</th>
              <th className="text-right">{t('labels.qty')}</th>
              <th className="text-right">{t('labels.price')}</th>
              <th className="text-right">{t('labels.total')}</th>
              <th className="text-right">{t('labels.action')}</th>
            </tr>
          </thead>
          <tbody>
            {props.orderItems.map(item => {
              return (
                <tr key={item.id}>
                  <td>
                    <small>
                      {item.name}
                      {item.note ? ' (' + item.note + ')' : ''}
                      <MdPlaylistAdd
                        size={20}
                        className="ml-2 pb-0"
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.handleChangeItemNote(item)}
                      />
                    </small>
                  </td>
                  <td className="text-right">{item.orderQuantity}</td>
                  <td className="text-right">
                    <div className="text-nowrap">
                      {parseFloat(item.price) === 0.0 ? (
                        <FaEdit
                          size={20}
                          className="mr-2 pb-1"
                          style={{ cursor: 'pointer' }}
                          onClick={() => props.handleChangeItemPrice(item)}
                        />
                      ) : (
                        ''
                      )}
                      {item.price > 0 ? parseFloat(item.price).toFixed(2) : 0}
                    </div>
                  </td>
                  <td className="text-right">
                    {parseFloat(item.orderQuantity * item.price).toFixed(2)}
                  </td>
                  <td className="text-right">
                    <AiOutlineMinusSquare
                      size={30}
                      color="#c40303"
                      style={{ cursor: 'pointer' }}
                      onClick={() => props.handleProductRemoveClick(item.id)}
                    />
                    <AiOutlinePlusSquare
                      size={30}
                      color="#31aa45"
                      style={{ cursor: 'pointer' }}
                      onClick={() => props.handleProductClick(item.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between pt-5 pl-3 pr-3">
        <div>
          <h6>{t('labels.total')}</h6>
        </div>
        <div>
          <h3>{props.total?.toFixed(2)}</h3>
        </div>
      </div>
    </>
  );
};

export default OrderItems;
