import axios from 'axios';

import { getToken, getProperty, logout } from './auth.js';

const api = axios.create();

api.defaults.headers = {
  'Content-Type': 'text/plain;charset=utf-8',
};

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.authorization = token;
  }
  return config;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      const property = getProperty();
      logout();
      return (window.location.href = `/${property}`);
    }
    return error;
  }
);

export default api;
