import api from '../../services/api';

const fetchGuests = () => {
  return api.get('/property/guests').then(response => {
    if (response.data.status) {
      return response;
    }
    throw new Error(response.data.message);
  });
};

export { fetchGuests };
