import * as actionTypes from './actionTypes';
import * as propertyRepository from '../../repositories/property';

export const fetchPropertyInfoInit = () => {
  return {
    type: actionTypes.FETCH_PROPERTY_INFO_INIT
  };
};

export const fetchPropertyInfoSuccess = propertyInfo => {
  return {
    type: actionTypes.FETCH_PROPERTY_INFO_SUCCESS,
    propertyInfo: propertyInfo,
  };
};

export const fetchPropertyInfoFail = error => {
  return {
    type: actionTypes.FETCH_PROPERTY_INFO_FAIL,
    error: error,
  };
};

export const fetchPropertyInfo = () => {
  return dispatch => {
    dispatch(fetchPropertyInfoInit());
    propertyRepository.fetchPropertyInfo()
      .then(response => {
        dispatch(fetchPropertyInfoSuccess(response.data.propertyInfo));
      })
      .catch(error => {
        dispatch(fetchPropertyInfoFail('There was a problem within get property info request.'));
      });
  };
};
