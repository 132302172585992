import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './styles/global';

import {
  getProperty,
  setProperty,
  unsetProperty,
  logout,
} from './services/auth';

import api from './services/api';
import Routes from './routes';
import { CUSTOM_ENDPOINT } from './config';
import ErrorBoundary from './ErrorBoundary';

/**
 * Salva no local storage a propriedade do usuario, e se for hqbox, ajusta o endpoint.
 */
const InitProperty = () => {
  const pathName = window.location.pathname;
  const pathNameParts = pathName.split('/');
  const property = pathNameParts[1] ? pathNameParts[1] : null;

  const storedProperty = getProperty();
  if (storedProperty !== null && storedProperty !== property) {
    unsetProperty();
    logout();
    return (window.location.href = `/${property}`);
  }

  setProperty(property);

  const { REACT_APP_ENV: appEnv } = process.env;
  let { REACT_APP_API_URL: apiBaseUrl } = process.env;

  if (
    appEnv === 'production' &&
    (property === 'bamboorio' || property === 'greenhaven')
  ) {
    apiBaseUrl = CUSTOM_ENDPOINT[property];
  }
  api.interceptors.request.use(
    async config => {
      config.baseURL = apiBaseUrl;
      return config;
    },
    error => Promise.reject(error)
  );
};

const App = () => {
  InitProperty();
  return (
    <ErrorBoundary>
      <ToastContainer
        position="bottom-center"
        style={{ width: '500px' }}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeOnClick
      />
      <GlobalStyle />
      <Routes />
    </ErrorBoundary>
  );
};

export default App;
