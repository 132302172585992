import React from 'react';

import { useTranslation } from 'react-i18next';

import { Container } from './styles.js';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <Container className="text-center mt-5">
      <h1 className="not-found">404</h1>
      <h3 className="mt-5">
        Ooops :(
        <br />
        <br />
        {t('errors.pageNotFound')}
      </h3>
    </Container>
  );
};

export default PageNotFound;
