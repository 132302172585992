import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';
import { FaPrint } from 'react-icons/fa';

import api from '../../../services/api';
import { getProperty } from '../../../services/auth';

import Layout from '../../../components/Layout/Layout';
import Modal from '../../../components/Modal';
import Cancellation from '../../../components/Tabs/Tab/Cancellation';
import MainDetail from '../../../components/Tabs/Tab/MainDetail';

import Loader from '../../../components/Loader';

import { openCouponWindow } from '../../../helper/print';

import { TabInfoCard } from './styles.js';

const TabInfo = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(null);
  const [showModal, setShowModal] = useState(false);

  let { id } = useParams();
  let history = useHistory();

  const getTabInfo = async () => {
    try {
      const response = await api.get(`/tabs/tabInfo?id=${id}`);
      if (response.data.status === true) {
        setTab(response.data.result);
      }
      setLoading(false);
    } catch (err) {
      toast.error('There was a problem within the request.');
      setLoading(false);
    }
  };
  useEffect(() => {
    getTabInfo();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductRemoveClick = itemId => {
    setLoading(true);

    api
      .post('/tabs/decrementItem', { itemId: itemId })
      .then(response => {
        if (response.data.success) {
          getTabInfo();
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(error => {
        console.log('Error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleProductAddClick = itemId => {
    setLoading(true);

    api
      .post('/tabs/incrementItem', { itemId: itemId })
      .then(response => {
        if (response.data.success) {
          getTabInfo();
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(error => {
        console.log('Error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirmTabCancellation = () => {
    showModalToggle();
    setLoading(true);
    api
      .post('/tabs/cancelTab', { id: id })
      .then(response => {
        if (response.data.success) {
          history.push(`/${getProperty()}/tabs`);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(error => {
        console.log('Error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelButtonClick = id => {
    setShowModal(true);
  };

  const showModalToggle = e => {
    setShowModal(!showModal);
  };

  const handlePrint = () => {
    api
      .post('/tabs/print', { tabId: id })
      .then(response => {
        if (response.data.status && response.data.result.printType) {
          openCouponWindow(response.data.result.pdfUrl);
        }
      })
      .catch(error => {
        console.log('Error', error);
      })
      .finally(() => {});
  };

  return (
    <Layout>
      <Modal
        show={showModal}
        toggleModal={showModalToggle}
        title="Tab Cancellation"
        handleConfirmationButtonClick={handleConfirmTabCancellation}
      >
        <Cancellation tab={tab} />
      </Modal>

      {loading && <Loader />}

      {!loading && tab && (
        <TabInfoCard className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <div>
                <h4>
                  {t('labels.tab')}: {tab.tab_number}
                </h4>
                <small>
                  {tab.date_time} - {tab.tab_create_user}
                </small>
              </div>
              <div>
                <button
                  className="btn btn-info hidden-print"
                  onClick={() => handlePrint()}
                >
                  <FaPrint size={20} />
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="card-text">
              <MainDetail tab={tab} />
              {tab.items.length > 0 && (
                <div className="table-responsive">
                  <table className="table table-striped table-hover table-collapsed">
                    <thead>
                      <tr>
                        <th className="text-left">{t('labels.item')}</th>
                        <th className="text-center">{t('labels.order')}</th>
                        <th className="text-right">{t('labels.qty')}</th>
                        <th className="text-right">{t('labels.price')}</th>
                        <th className="text-right">{t('labels.total')}</th>
                        <th className="text-center">{t('labels.date')}</th>
                        <th className="text-center">{t('labels.user')}</th>
                        <th className="text-right">{t('labels.action')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tab.items.map(item => {
                        return (
                          <tr key={item.id}>
                            <td className="text-left">{item.name}</td>
                            <td className="text-center">{item.order_id}</td>
                            <td className="text-right">{item.total_qty}</td>
                            <td className="text-right">
                              {parseFloat(item.price).toFixed(2)}
                            </td>
                            <td className="text-right">
                              {parseFloat(item.total_price).toFixed(2)}
                            </td>
                            <td className="text-center">{item.item_date}</td>
                            <td className="text-center">
                              {item.item_create_user}
                            </td>
                            <td className="text-right">
                              <AiOutlineMinusSquare
                                className=""
                                size={30}
                                color="#c40303"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  handleProductRemoveClick(item.id)
                                }
                              />
                              <AiOutlinePlusSquare
                                size={30}
                                color="#31aa45"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleProductAddClick(item.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td className="text-right" colSpan="4">
                          <h6>Total:</h6>
                        </td>
                        <td className="text-right">
                          <h6>{parseFloat(tab.total).toFixed(2)}</h6>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              )}
            </div>
            <button className="btn btn-info" onClick={() => history.goBack()}>
              ❮ {t('labels.goBack')}
            </button>
            <button
              className="btn btn-danger float-right"
              onClick={() => handleCancelButtonClick()}
            >
              {t('labels.cancel')}
            </button>
          </div>
        </TabInfoCard>
      )}
    </Layout>
  );
};

export default TabInfo;
