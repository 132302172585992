import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ItemNote = props => {
  const [note, setNote] = useState('');
  const { t } = useTranslation();

  return (
    <form>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">{t('labels.notes')}</label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            rows={3}
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label"></label>
        <div className="col-sm-10">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => props.handleSaveItemNoteClick(note)}
          >
            {t('labels.save')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ItemNote;
