import styled from 'styled-components';

export const Container = styled.div`
  .not-found {
    font-size: 186px;
  }

  .send {
    background: #687adb;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 10px;
    text-align: center;
    padding: 14px 24px 15px 24px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.21);
  }
  .send:hover {
    background: #4a529e;
  }
`;
