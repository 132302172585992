import styled from 'styled-components';

export const Container = styled.div`
  .login-column {
    width: 450px;
  }

  .login-box {
    margin-top: 120px;
    max-width: 600px;
    height: 420px;
    border: 1px solid #4f4f4f;
    background-color: #fff;
    color: #4f4f4f;
  }

  @media (max-width: 450px) {
    .login-column {
      width: 100%;
    }
    .login-box {
      margin-top: 40px;
      border: 0px;
    }
  }
`;

export const Form = styled.form`
  padding: 20px;
  margin: 50px 0;

  .btn {
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 20px;
    padding: 10px 30px;
    border: 0px none;
    transition: background 0.5s, color 0.5s;
  }
  .btn:hover,
  .btn:active {
    border: 0px none;
  }
  .btn.btn-primary {
    background: #4f4f4f;
    color: #c7da00;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 10px;
    text-align: center;
    padding: 14px 24px 15px 24px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.21);
  }
  .btn.btn-primary:hover {
    background: #272727;
  }

  .btn-primary {
    padding: 17px 40px 17px 40px;
  }
  input[type='text'],
  input[type='email'],
  input[type='password'],
  select {
    height: 46px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #f8f8f8;
    border: 1px solid #4f4f4f;
    border-radius: 3px;
    opacity: 1;
  }
  input[type='text']:focus,
  input[type='email']:focus,
  input[type='password']:focus,
  select:focus {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #353a40 !important;
    border: 1px solid #4f4f4f;
    border-radius: 3px;
    opacity: 1;
  }
`;
