import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader';
import { fetchTabs, emptyTabs } from '../../store/actions';
import { Table } from './styles.js';

const Tabs = () => {
  const { t } = useTranslation();

  const loading = useSelector(state => state.tabsReducer.loading);
  const tabs = useSelector(state => state.tabsReducer.tabs);
  const error = useSelector(state => state.tabsReducer.error);
  const [filteredTabs, setFilteredTabs] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTabs());
    return () => {
      dispatch(emptyTabs());
    };
  }, [dispatch]);

  useEffect(() => {
    setFilteredTabs(tabs);
    return () => {
      setFilteredTabs([]);
    };
  }, [tabs]);

  useEffect(() => {
    toast.error(error);
  }, [error]);

  const handleSearch = event => {
    const searchString = event.target.value;
    const changedTabs = tabs?.filter(tab => {
      return (
        (tab.name !== null &&
          tab.name.toLowerCase().includes(searchString.toLowerCase())) ||
        (tab.email !== null &&
          tab.email.toLowerCase().includes(searchString.toLowerCase())) ||
        (tab.tab_number !== null && tab.tab_number.includes(searchString))
      );
    });

    setFilteredTabs(changedTabs);
  };

  let table = '';
  if (loading) {
    table = <Loader />;
  } else if (filteredTabs) {
    table = (
      <>
        <div className="d-flex justify-content-end">
          <input
            type="text"
            name="search"
            id="search"
            placeholder={t('labels.search')}
            className="form-control mb-2 rounded-0 col-xs-12 col-sm-12 col-md-4 col-lg-3"
            onChange={event => handleSearch(event)}
          />
        </div>
        <div className="table-responsive">
          <Table className="table table-hover table-white">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col" className="text-center">
                  {t('labels.date')}
                </th>
                <th scope="col" className="text-right">
                  {t('labels.total')}
                </th>
                <th scope="col">{t('labels.name')}</th>
                <th scope="col">{t('labels.email')}</th>
                <th scope="col">{t('labels.phone')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredTabs.map(tab => {
                return (
                  <tr key={tab.id}>
                    <th scope="row">
                      <Link
                        to={`tabs/${tab.id}`}
                        className="btn btn-info btn-sm col-12"
                        title="View tab info"
                      >
                        {tab.tab_number}
                      </Link>
                    </th>
                    <td className="text-center">{tab.date_time}</td>
                    <td className="text-right">
                      {tab.total > 0 ? parseFloat(tab.total).toFixed(2) : 0.0}
                    </td>
                    <td>
                      {tab.name}
                      {tab.tbk_id !== null ? (
                        <small className="ml-2">
                          {t('labels.booking')} #{tab.tbk_id}
                        </small>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{tab.email}</td>
                    <td>{tab.phone}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  return (
    <Layout>
      <h1>{t('labels.tabs')}</h1>
      {table}
    </Layout>
  );
};

export default Tabs;
