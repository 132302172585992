export const openCouponWindow = pdfUrl => {
  let top = window.screen.height - 300;
  top = top > 0 ? top / 2 : 0;
  let left = window.screen.width - 400;
  left = left > 0 ? left / 2 : 0;
  window.open(
    pdfUrl,
    'TAB',
    'width=500,height=400,top=' + top + ',left=' + left
  );
};
