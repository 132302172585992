import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { getProperty } from '../../services/auth';
import { useTranslation } from 'react-i18next';

const Nav = () => {
  const { t } = useTranslation();
  const property = getProperty();
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark flex-row">
        <NavLink className="navbar-brand mr-3" alt="hqbeds" to={`/${property}`}>
          <img src={logo} alt="hqbeds" />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={`/${property}/order`}
                exact
              >
                {t('labels.order')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={`/${property}/tabs`}
                exact
              >
                {t('labels.tabs')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={`/${property}/register`}
                exact
              >
                {t('labels.register')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={`/${property}/monitor`}
                exact
              >
                {t('labels.ordersMonitor')}
              </NavLink>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item text-nowrap">
              <NavLink className="nav-link" to={`/${property}/signout`}>
                {t('labels.signout')}
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Nav;
