import * as actionTypes from './actionTypes';
import * as tabsRepository from '../../repositories/tabs';

export const fetchTabsInit = () => {
  return {
    type: actionTypes.FETCH_TABS_INIT,
  };
};

export const fetchTabsSuccess = tabs => {
  return {
    type: actionTypes.FETCH_TABS_SUCCESS,
    tabs: tabs,
  };
};

export const fetchTabsFail = error => {
  return {
    type: actionTypes.FETCH_TABS_FAIL,
    error: error,
  };
};

export const emptyTabs = () => {
  return {
    type: actionTypes.EMPTY_TABS,
  };
};

export const fetchTabs = () => {
  return dispatch => {
    dispatch(fetchTabsInit());
    tabsRepository.fetchTabs()
      .then(response => {
        dispatch(fetchTabsSuccess(response.data.result));
      })
      .catch(error => {
        dispatch(fetchTabsFail('There was a problem within get tabs request.'));
      });
  };
};
