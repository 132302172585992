import * as actionTypes from './actionTypes';
import * as guestsRepository from '../../repositories/guests';

export const fetchGuestsInit = () => {
  return {
    type: actionTypes.FETCH_GUESTS_INIT,
  };
};

export const fetchGuestsSuccess = guests => {
  return {
    type: actionTypes.FETCH_GUESTS_SUCCESS,
    guests: guests,
  };
};

export const fetchGuestsFail = error => {
  return {
    type: actionTypes.FETCH_GUESTS_FAIL,
    error: error,
  };
};

export const fetchGuests = () => {
  return dispatch => {
    dispatch(fetchGuestsInit());
    guestsRepository.fetchGuests()
      .then(response => {
        dispatch(fetchGuestsSuccess(response.data.guests));
      })
      .catch(error => {
        dispatch(
          fetchGuestsFail('There was a problem within fetch guests request.')
        );
      });
  };
};
