import api from '../../services/api';

const fetchProductsGroups = (onlyGroups) => {
  return api.post('/products/sellingGroups', { onlyGroups }).then(response => {
    if (response.data.status) {
      return response;
    }
    throw new Error(response.data.message);
  });
};

export { fetchProductsGroups };
