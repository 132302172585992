import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated, logout, getProperty } from "./services/auth";

import SignIn from "./pages/SignIn";
import Property from "./pages/Property";
import Tabs from "./pages/Tabs";
import TabInfo from "./pages/Tabs/TabInfo";
import Order from "./pages/Order";
import PageNotFound from "./pages/PageNotFound";
import TabRegister from "./pages/TabRegister";
import OrdersMonitor from "./pages/OrdersMonitor";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const property = getProperty();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `/${property}/`, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const property = getProperty();
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/:property/" component={SignIn} />
        <PrivateRoute path="/:property/property" component={Property} />
        <PrivateRoute path="/:property/tabs/:id" component={TabInfo} />
        <PrivateRoute path="/:property/tabs" component={Tabs} />
        <PrivateRoute path="/:property/order" component={Order} />
        <PrivateRoute path="/:property/monitor" component={OrdersMonitor} />
        <PrivateRoute path="/:property/register" component={TabRegister} />
        <PrivateRoute
          path="/:property/signout"
          component={() => {
            logout();
            return <Redirect to={`/${property}/`} />;
          }}
        />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
