import * as actionTypes from '../actions/actionTypes';
import { updatedObject } from '../utils';

const initialState = {
  error: null,
  loading: false,
  defaultLanguageCode: null,
  propertyName: null,
  orderTime: null,
  systemTimezoneDesc: null,
};

const fetchPropertyInfoInit = state => {
  return updatedObject(state, { loading: true });
};

const fetchPropertyInfoSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    defaultLanguageCode: action.propertyInfo.defaultLanguageCode,
    propertyName: action.propertyInfo.property_name,
    systemTimezoneDesc: action.propertyInfo.system_timezone_desc,
    orderTime: action.propertyInfo.orderTime,
  });
};

const fetchPropertyInfoFail = (state, action) => {
  return updatedObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROPERTY_INFO_INIT:
      return fetchPropertyInfoInit(state);
    case actionTypes.FETCH_PROPERTY_INFO_SUCCESS:
      return fetchPropertyInfoSuccess(state, action);
    case actionTypes.FETCH_PROPERTY_INFO_FAIL:
      return fetchPropertyInfoFail(state, action);
    default:
      return state;
  }
};

export default reducer;
