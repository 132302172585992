import React from 'react';

import { useTranslation } from 'react-i18next';
import { Container, ErrorBlock } from './styles.js';

const ErrorFallback = ({error, resetErrorBoundary}) => {
  const { t } = useTranslation();

  return (
    <Container className="text-center mt-5">
      <h3 className="mt-5">
        Ooops :(
        <br />
        <br />
        {t('errors.invalidRequest')}
        <ErrorBlock>
          {error}
        </ErrorBlock>
      </h3>
    </Container>
  );
};

export default ErrorFallback;
