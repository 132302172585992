import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Search = (props) => {
  const { t } = useTranslation();

  const [filteredTabs, setFilteredTabs] = useState([]);
  useEffect(() => {
    setFilteredTabs(props.tabs);
  }, [props.tabs]);

  const handleSearch = (event) => {
    const searchString = event.target.value;
    const changedTabs = props.tabs?.filter((tab) => {
      return (
        (tab.name !== null &&
          tab.name.toLowerCase().includes(searchString.toLowerCase())) ||
        (tab.email !== null &&
          tab.email.toLowerCase().includes(searchString.toLowerCase())) ||
        (tab.tab_number !== null && tab.tab_number.includes(searchString))
      );
    });

    setFilteredTabs(changedTabs);
  };
  return (
    <div>
      <div className="d-flex">
        <input
          type="text"
          name="search"
          id="search"
          placeholder={t("labels.search")}
          className="form-control mb-1 rounded-0 col-12"
          onChange={(event) => handleSearch(event)}
        />
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-sm table-hover mt-1">
          <thead>
            <tr>
              <th>{t("labels.tab")}</th>
              <th>{t("labels.name")}</th>
              <th>{t("labels.email")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredTabs?.map((tab) => {
              return (
                <tr
                  key={tab.id}
                  onClick={() => props.handleTabSelect(tab)}
                  style={{ cursor: "pointer" }}
                >
                  <th>{tab.tab_number}</th>
                  <td>
                    {tab.name}
                    {tab.tbk_id !== null ? <small> #{tab.tbk_id}</small> : ""}
                  </td>
                  <td>{tab.email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Search;
