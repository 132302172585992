import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
body,
html {
  font-family: "Montserrat", sans-serif; 
  font-weight: 300;
  background-color: #f4f4f4;
}
@media (max-width: 450px) {
    html {
      background-color: #fff;
    }
    body{
      background-color: #fff;
      height: 100vh;
    }
  }
  
`;

export default GlobalStyle;
